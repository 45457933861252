<template>
  <div>
    <div class="mb-10">
      <div class="text-lg font-bold mb-5">Referees</div>
    </div>

    <div class="grid grid-cols-1 mb-4">
      <search-form
        v-model="searchQuery"
        placeholder="Search for user's name, status, etc."
        @submit="(event) => $refs.table.loadAjaxData()"
      />
    </div>
    <datatable
      :url="url"
      :ajax="true"
      :ajaxPagination="true"
      :index="true"
      :reverse-index="true"
      :columns="referralsColumns"
      :pageDetails="true"
      :fillable="true"
      :loading="loading"
      :query="searchQuery"
      dropdown="actions"
      ref="table"
    />
  </div>
</template>
<script>
export default {
  props: {
    staff: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      url: `${this.$baseurl}/admin/personal/users/referral-referrer`,
      loading: false,
      error: false,
      searchQuery: '',
      referralsColumns: [
        {
          name: 'user_id',
          th: 'User Id',
          render: (referrals) => {
            return referrals?.referred?.id;
          },
        },
        {
          name: 'full_name',
          th: 'Name',
          render: (referrals) => {
            return referrals?.referred?.full_name;
          },
        },
        {
          name: 'referrer.email',
          th: 'Referrer Email',
          render: (referrals) => {
            return referrals?.referrer?.email;
          },
        },
        {
          name: 'created_date',
          th: 'Registration Date',
          render: (referrals) => {
            return this.$options.filters.dateFormat(
              referrals?.referred?.created_at,
              'D, dd M Y'
            );
          },
        },
        {
          name: 'status',
          th: 'Status',
          render: (referrals) => {
            switch (referrals.status) {
              case 'completed':
                return `<div class="badge badge-green-soft-outline">
                    Completed
                  </div>`;
              default:
                return `<div class="badge badge-orange-soft-outline">
                    Pending
                  </div>`;
            }
          },
        },
      ],
    };
  },
  mounted() {
    this.$options.listenForClick.apply(this);
  },
};
</script>
